import React from 'react'

import { useRouter } from 'next/router'

import { CloseButton } from 'components/BottomSheet/CloseButton/CloseButton'
import { WeatherStationTable } from './WeatherStationTable'
import { WeatherStation } from 'components/Routing/WeatherStation/WeatherStation'
import { Sponsor } from 'components/Sponsor/Sponsor'
import { useGoToHome, useHistory } from 'stores/ViewStore'
import { WEATHER_STATION } from 'constants/products'

export const WeatherStationListView = ({ productID, setProduct }) => {
    const router = useRouter()

    const goToHome = useGoToHome()
    const history = useHistory()

    const handleXClick = () => {
        // get the previous view
        const { view, id } = history[history.length - 2]
        if (view === WEATHER_STATION && id === null) {
            // We came from the menu, go back to the menu
            router.back()
        } else {
            // We came from the map, go back to the main menu
            goToHome()
        }
    }

    return (
        <>
            {productID ? (
                <>
                    <div style={style.flex}>
                        <Sponsor type="Weather" />
                        <CloseButton callback={handleXClick} />
                    </div>
                    <WeatherStation id={productID} />
                </>
            ) : (
                <WeatherStationTable select={id => setProduct(id)} />
            )}
        </>
    )
}

const style = {
    flex: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
}
