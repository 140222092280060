import React from 'react'

import { FormattedMessage } from 'react-intl'
import WarningIcon from '@mui/icons-material/Warning'
import { RED } from 'constants/products/forecast/ratings/palette'
import {
    ACCIDENT,
    ICE_CLIMB,
    MOUNTAIN_CONDITIONS_REPORT,
    MOUNTAIN_INFORMATION_NETWORK,
    SPAW,
    WEATHER_STATION,
} from 'constants/products'

import { useSpaws } from 'hooks/data/SPAW'
import {
    MountainInformationNetwork,
    MountainConditionsReport,
    WeatherStation,
    FatalAccident,
    IceClimbingAtlas,
} from 'components/icons'
import MountainWeatherForecast from 'components/icons/MountainWeatherForecast'
import { MapGeocoder } from 'components/geocoder'
import Shim from 'components/Shim/Shim'
import { Avatar } from 'components/Avatar/Avatar'
import { useUserAvatar } from 'contexts/authentication'

const ExploreOption = ({ label, description, icon, callback }) => {
    return (
        <div onClick={callback} style={exploreStyles.container}>
            <span style={exploreStyles.iconContainer}>{icon}</span>
            <div style={exploreStyles.labelContainer}>
                <div style={exploreStyles.labelRow}>
                    <h1 style={exploreStyles.label}>{label}</h1>
                </div>
                <p style={exploreStyles.sub}>{description}</p>
            </div>
        </div>
    )
}

export const Explorer = ({ setView }) => {
    const { data: spaws } = useSpaws()
    const avatar = useUserAvatar()

    return (
        <>
            <Shim horizontal>
                <div style={exploreStyles.flex}>
                    <MapGeocoder />
                    <Avatar callback={() => setView('Account')} src={avatar} />
                </div>
            </Shim>
            {spaws && (
                <div style={exploreStyles.optionContainer}>
                    <ExploreOption
                        label={
                            <FormattedMessage
                                id="drawer.spaw"
                                defaultMessage="Special Public Avalanche Warning"
                                descripton="Map View Drawer Button"
                            />
                        }
                        description={
                            <FormattedMessage
                                defaultMessage="Special warnings for unusually dangerous avalanche conditions"
                                description="Explorer View Drawer"
                            />
                        }
                        callback={() => setView(SPAW)}
                        icon={<WarningIcon height={'35px'} width={'35px'} style={{ color: RED }} />}
                    />
                </div>
            )}
            <div style={exploreStyles.optionContainer}>
                <ExploreOption
                    label={
                        <FormattedMessage
                            id="drawer.min"
                            defaultMessage="MIN Reports"
                            descripton="Map View Drawer Button"
                        />
                    }
                    description={
                        <FormattedMessage
                            defaultMessage="Real-time snow, ice, avalanche & weather conditions"
                            description="Explorer View Drawer"
                        />
                    }
                    callback={() => setView(MOUNTAIN_INFORMATION_NETWORK)}
                    icon={<MountainInformationNetwork height={'35px'} width={'35px'} />}
                />
            </div>
            <div style={exploreStyles.optionContainer}>
                <ExploreOption
                    label={
                        <FormattedMessage
                            id="drawer.mcr"
                            defaultMessage="Mountain Conditions Reports"
                            descripton="Map View Drawer Button"
                        />
                    }
                    description={
                        <FormattedMessage
                            defaultMessage="Field observations made by professional guides"
                            description="Explorer View Drawer"
                        />
                    }
                    callback={() => setView(MOUNTAIN_CONDITIONS_REPORT)}
                    icon={<MountainConditionsReport height={'35px'} width={'35px'} />}
                />
            </div>
            <div style={exploreStyles.optionContainer}>
                <ExploreOption
                    label={
                        <FormattedMessage
                            id="drawer.weatherstations"
                            defaultMessage="Weather Stations"
                            descripton="Map View Drawer Button"
                        />
                    }
                    description={
                        <FormattedMessage
                            defaultMessage="Air, temperature, wind, and snow conditions"
                            description="Explorer View Drawer"
                        />
                    }
                    callback={() => setView(WEATHER_STATION)}
                    icon={<WeatherStation height={'33px'} width={'28px'} />}
                />
            </div>
            <div style={exploreStyles.optionContainer}>
                <ExploreOption
                    label={
                        <FormattedMessage
                            id="drawer.MWF"
                            defaultMessage="Mountain Weather Forecast"
                            descripton="Map View Drawer Button"
                        />
                    }
                    description={
                        <FormattedMessage
                            defaultMessage="The Meteorological Service of Canada’s weather forecast"
                            description="Explorer View Drawer"
                        />
                    }
                    callback={() => window.open('https://www.avalanche.ca/weather/forecast')}
                    icon={<MountainWeatherForecast height={35} width={35} />}
                />
            </div>
            <div style={exploreStyles.optionContainer}>
                <ExploreOption
                    label={
                        <FormattedMessage
                            id="drawer.ice-climbs"
                            defaultMessage="Ice Climbing Atlas"
                            descripton="Map View Drawer Button"
                        />
                    }
                    description={
                        <FormattedMessage defaultMessage="ATES-rated ice climbs" description="Explorer View Drawer" />
                    }
                    callback={() => setView(ICE_CLIMB)}
                    icon={<IceClimbingAtlas height={'35px'} width={'35px'} />}
                />
            </div>
            <div style={exploreStyles.optionContainer}>
                <ExploreOption
                    label={
                        <FormattedMessage
                            id="drawer.accidents"
                            defaultMessage="Fatal Avalanche Incidents"
                            descripton="Map View Drawer Button"
                        />
                    }
                    description={
                        <FormattedMessage
                            defaultMessage="Avalanche fatalities during the current season"
                            description="Explorer View Drawer"
                        />
                    }
                    callback={() => setView(ACCIDENT)}
                    icon={<FatalAccident height={'35px'} width={'35px'} />}
                />
            </div>
        </>
    )
}

const exploreStyles = {
    flex: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: '0.75rem',
        marginBottom: '0.5rem',
    },
    optionContainer: {
        padding: '7px 10px',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '5px',
        overflow: 'hidden',
        height: '80px',
        boxShadow: 'var(--shadow)',
    },
    imageContainer: {
        width: '100px',
        height: '100px',
    },
    image: {
        width: '100px',
        height: '100px',
    },
    labelRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    label: {
        fontSize: '1rem',
        fontWeight: '500',
        margin: '0',
    },
    sub: {
        fontSize: '12px',
        fontWeight: '400',
        margin: '0',
        color: 'var(--gray)',
    },
    labelContainer: {
        padding: '3px 10px',
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        justifyContent: 'center',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
    },
}
