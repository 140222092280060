import useSWR from 'swr'

import * as URLUtils from 'utils/avcan-website-utils/url'
import client from 'clients/geocoder'

const base = process.env.NEXT_PUBLIC_GEOCODER_API_URL

export function useSearchTerm(term) {
    const key = URLUtils.buildPath(base, 'v1', 'search', term)

    return useSWR(term ? key : null, () => client.search(term))
}
