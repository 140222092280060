import * as Turf from '@turf/helpers'
import { differenceInCalendarDays } from 'date-fns'
import * as ArrayUtils from '../../lib/array'
import * as FilterUtils from '../../lib/filter'
import { MOUNTAIN_INFORMATION_NETWORK as product } from 'constants/products'

export function createFeature({ id, title, location, observations, tags, datetime }) {
    const { longitude, latitude } = location
    const age = differenceInCalendarDays(new Date(), new Date(datetime))
    const properties = { product, id, title, age, tags, ...observations }

    return Turf.point([longitude, latitude], properties, { id })
}

export function createFeatureCollection(data = []) {
    return Turf.featureCollection(data.map(createFeature))
}

export function filterFeatureCollection(collection, values) {
    const filters = FilterUtils.create(values, FeatureFilters)
    const features = ArrayUtils.filter(collection.features, filters)

    return Turf.featureCollection(features)
}

const FeatureFilters = new Map([
    ['age', age => feature => feature.properties.age <= age],
    [
        'obtypes',
        obtypes => {
            const obTypes = Array.from(obtypes)

            return feature => obTypes.some(obtype => feature.properties[obtype] > 0)
        },
    ],
    ['tags', tags => feature => feature.properties.tags.some(tag => tags.has(tag))],
])
