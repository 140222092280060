import React from 'react'

import { useRouter } from 'next/router'

import { CloseButton } from 'components/BottomSheet/CloseButton/CloseButton'
import { MCRTable } from 'components/MCR/MCRTable'
import { MCR } from 'components/Routing/MCR/MCR'
import { useGoToHome, useHistory } from 'stores/ViewStore'
import { MOUNTAIN_CONDITIONS_REPORT } from 'constants/products'

export const MCRListView = ({ productID, setProduct }) => {
    const router = useRouter()

    const goToHome = useGoToHome()
    const history = useHistory()

    const handleXClick = () => {
        // get the previous view
        const { view, id } = history[history.length - 2]
        if (view === MOUNTAIN_CONDITIONS_REPORT && id === null) {
            // We came from the menu, go back to the menu
            router.back()
        } else {
            // We came from the map, go back to the main menu
            goToHome()
        }
    }

    return (
        <>
            {productID ? (
                <>
                    <CloseButton callback={handleXClick} /> <MCR id={productID} />
                </>
            ) : (
                <MCRTable select={id => setProduct(id)} />
            )}
        </>
    )
}
