import { subDays } from 'date-fns'
import { Predicates } from './'
import * as PredicatesUtils from './utils/predicates'
import * as Posts from './utils/posts'
import * as Utils from './utils'
import { EVENT as type } from 'prismic/types'
import { DateParam } from 'utils/avcan-website-utils/params'

export function get(uid, locale) {
    return Posts.get(type, uid, locale)
}

export function getList(params = {}, locale) {
    const { past, tags, page = 1 } = params
    const ordering = past ? ORDERING + ' desc' : ORDERING
    const predicates = [createTimelineEventPredicate(past)]
    const options = {
        orderings: createOrderings(ordering),
        page,
        locale,
    }

    if (tags?.size > 0) {
        predicates.push(PredicatesUtils.tags(tags))
    }

    return Posts.getList(predicates, options)
}

export function getRelated(uid, locale) {
    const predicate = createUpcomingEventPredicate()
    const orderings = createOrderings(ORDERING)

    return Posts.getRelated(type, uid, orderings, locale, [predicate])
}

// TOOD Need to use that function!
export function getForTags(tags, options = {}) {
    const fragment = PredicatesUtils.my(type, 'end_date')
    const after = DateParam.format(subDays(new Date(), 1))
    const predicates = [Predicates.dateAfter(fragment, after)]
    const orderings = createOrderings(ORDERING)

    return Posts.getForTags({ type, tags, orderings, predicates, ...options })
}

export function getTags() {
    return Utils.getTags(type)
}

// Utils & constants
const ORDERING = PredicatesUtils.my(type, 'start_date')
function createOrderings(ordering) {
    return '[' + ordering + ']'
}
function createUpcomingEventPredicate() {
    return createTimelineEventPredicate()
}
function createPastEventPredicate() {
    return createTimelineEventPredicate(true)
}
function createTimelineEventPredicate(past) {
    const timestamp = DateParam.format(subDays(new Date(), 1))
    const createPredicate = past ? Predicates.dateBefore : Predicates.dateAfter

    return createPredicate(PredicatesUtils.my(type, 'end_date'), timestamp)
}
