import { Client, Interceptors } from '../../../fetch'
import * as URLUtils from '../../../url'
import LOCALE from 'constants/locales'

export default class MountainInformationNetworkClient {
    constructor(base, key) {
        const url = URLUtils.buildPath(base, 'min')

        this.client = new Client(url, new Interceptors.CacheKey(), new Interceptors.APIKey(key))
    }
    submission(id, locale = LOCALE) {
        return this.client.get([locale, 'submissions', id])
    }
    submissions(params = {}, locale = LOCALE, getToken) {
        const base = URLUtils.buildPath([locale, 'submissions'])
        const { from, to, ...rest } = params
        const path = URLUtils.appendParams(base, {
            fromdate: from,
            todate: to,
            ...rest,
        })

        return this.client.get(path, getToken)
    }
}
