import { useRouter } from 'next/router'

import { ICE_CLIMB } from 'constants/products'
import { CloseButton } from 'components/BottomSheet/CloseButton/CloseButton'
import { IceClimbTable } from 'components/IceClimb/IceClimbTable'
import { useGoToHome, useHistory } from 'stores/ViewStore'
import { IceClimb } from './IceClimb'

export const IceClimbListView = ({ productID, setProduct }) => {
    const router = useRouter()

    const goToHome = useGoToHome()
    const history = useHistory()

    const handleXClick = () => {
        // get the previous view
        const { view, id } = history[history.length - 2]
        if (view === ICE_CLIMB && id === null) {
            // We came from the menu, go back to the menu
            router.back()
        } else {
            // We came from the map, go back to the main menu
            goToHome()
        }
    }

    return (
        <>
            {productID ? (
                <>
                    <CloseButton callback={handleXClick} />
                    <IceClimb id={productID} />
                </>
            ) : (
                <IceClimbTable select={id => setProduct(id)} />
            )}
        </>
    )
}
