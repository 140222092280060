import * as Turf from '@turf/helpers'
import { MOUNTAIN_CONDITIONS_REPORT as product } from 'constants/products'

export function createFeature({ location, title, id }) {
    const properties = { product, id, title }

    return Turf.point(location, properties, { id })
}

export function createFeatureCollection(data = []) {
    return Turf.featureCollection(data.map(createFeature))
}
