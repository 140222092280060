import { useMemo } from 'react'

import { iceClimbs } from 'constants/products/ice-climbs'
import * as Page from 'prismic/clients/page'
import { useMapState } from 'contexts/map/state'
import useSWRImmutable from 'swr/immutable'

import { useLocale } from 'stores/SettingsStore'

export const useIceClimb = id => {
    const locale = useLocale()
    const key = id ? [id, locale] : null
    const fetcher = (id, locale) => Page.get(id, locale)

    const { data, error } = useSWRImmutable(key, fetcher)

    return {
        data,
        isLoading: !error && !data,
        isError: error,
    }
}

export const useFilteredIceClimbs = () => {
    const { viewportBounds } = useMapState()
    const climbs = iceClimbs

    return {
        climbs: useMemo(() => {
            if (!climbs) {
                return null
            }

            return geographicallyFilterClimbs(climbs, viewportBounds.value)
        }, [climbs, viewportBounds]),
    }
}

function geographicallyFilterClimbs(climbs, bounds) {
    if (!bounds || !bounds._sw || !bounds._ne || !bounds._sw || !bounds._ne) {
        return climbs
    }

    return climbs.features.filter(
        ({ geometry }) =>
            geometry.coordinates[1] >= bounds._sw.lat &&
            geometry.coordinates[0] >= bounds._sw.lng &&
            geometry.coordinates[1] <= bounds._ne.lat &&
            geometry.coordinates[0] <= bounds._ne.lng
    )
}
