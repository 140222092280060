import React, { useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { Button, SvgIcon, Snackbar, Alert } from '@mui/material'
import { FORECAST } from 'constants/products'
import { MOUNTAIN_INFORMATION_NETWORK } from 'constants/products'

import { useForecasts } from 'hooks/data/forecasts'
import { useAppOpenedCount, useSetAppOpenedCount } from 'stores/SettingsStore'
import { setBottomSheetToHalfHeight, setBottomSheetToMinHeight } from 'stores/BottomSheetStore'
import { EXPLORER_LAYOUT, EXPLORER_VIEW } from 'constants/layout'
import { AUTO_HIDE_DURATION, TOP_CENTER } from 'constants/snackbar'
import { useAuth } from 'contexts/authentication'

export const ProductTour = ({ updateProduct, updateView, handleSetLayout, forceShowTour, setForceShowTour }) => {
    // Phones that are too small will have conflicting arrows over the text.
    // This is approx 320x658
    const isNotTooSmall = window.innerHeight * window.innerWidth > 260000

    // Boolean to decide whether to show the tour or not.
    // appOpenedCount = 0 ? show tour
    // appOpenedCount = 1 or 2 ? show notification asking if they want to see the tour
    // appOpenedCount = 3 or more ? don't show tour
    // Assume that if they're logged in they don't need the tour.
    const appOpenedCount = useAppOpenedCount()
    const setAppOpenedCount = useSetAppOpenedCount()
    const { isAuthenticated } = useAuth()
    const shouldShowTour = !isAuthenticated && appOpenedCount === 0
    const shouldShowNotification = !isAuthenticated && (appOpenedCount === 1 || appOpenedCount === 2)

    const [showTour, setShowTour] = useState(shouldShowTour)
    const [showTour1, setShowTour1] = useState(false)
    const [showTour2, setShowTour2] = useState(false)
    const [showTour3, setShowTour3] = useState(false)
    const [showTour4, setShowTour4] = useState(false)
    const [showNotification, setShowNotification] = useState(shouldShowNotification)

    useEffect(() => {
        if (forceShowTour) {
            setShowTour(true)
            setForceShowTour(false)
        }
    }, [forceShowTour])

    const forecasts = useForecasts()
    const forecast = forecasts.forecasts[0]

    const handleSkip = event => {
        event.stopPropagation()
        setShowTour(false)
        // Set the appOpenedCount to 1 so that the notification shows next time they open the app (presumably appOpenedCount is 0 at this point).
        setAppOpenedCount(appOpenedCount + 1)
    }

    const handleCloseNotification = () => {
        setShowNotification(false)
        // Set the appOpenedCount to 3 so that the tour or notifications doesn't show again.
        setAppOpenedCount(3)
    }

    const handleStartTour = () => {
        setShowNotification(false)
        setShowTour(true)
    }

    const handleNext1 = () => {
        setShowTour(false)
        setShowTour1(true)
        setAppOpenedCount(appOpenedCount + 1)
    }

    const handleNext2 = () => {
        setShowTour1(false)
        setBottomSheetToHalfHeight()
        setShowTour2(true)
    }

    const handleBack2 = event => {
        event.stopPropagation()
        setShowTour(true)
        setShowTour1(false)
    }

    const handleNext3 = () => {
        setShowTour2(false)
        setShowTour3(true)
        setBottomSheetToHalfHeight()
        updateView(MOUNTAIN_INFORMATION_NETWORK)
        // TODO: zoom in to see reports filtering. We're not in the map context so we can't use the flyTo hook.
    }

    const handleBack3 = event => {
        event.stopPropagation()
        setShowTour1(true)
        setShowTour2(false)
        setBottomSheetToMinHeight()
    }

    const handleNext4 = () => {
        setShowTour3(false)
        setShowTour4(true)
        updateProduct(FORECAST, forecast.id)
    }

    const handleBack4 = event => {
        event.stopPropagation()
        setShowTour2(true)
        setShowTour3(false)
        updateView(EXPLORER_VIEW)
    }

    const handleNext5 = () => {
        setShowTour4(false)
        setBottomSheetToMinHeight()
        handleSetLayout(EXPLORER_LAYOUT, true)
    }

    const handleBack5 = event => {
        event.stopPropagation()
        setShowTour3(true)
        setShowTour4(false)
        updateView(MOUNTAIN_INFORMATION_NETWORK)
    }

    // Intro to product tour
    const Mask = ({ handleNext1, handleSkip }) => {
        return (
            <div onClick={() => handleNext1()} style={styles.mask}>
                <h2 style={styles.welcome}>
                    <FormattedMessage
                        description="Product tour mask"
                        defaultMessage="Welcome to the Avalanche Canada App!"
                    />
                </h2>
                <div style={styles.subWelcomeText}>
                    <p>
                        <FormattedMessage description="Product tour mask" defaultMessage="Here's a quick tour" />
                    </p>
                </div>
                <div style={styles.buttonSpacing}>
                    <Button variant="contained" onClick={e => handleSkip(e)} sx={{ background: 'var(--gray)' }}>
                        <FormattedMessage description="Product tour mask" defaultMessage="Skip" />
                    </Button>
                    <Button variant="contained" onClick={() => handleNext1()}>
                        <FormattedMessage description="Product tour mask" defaultMessage="Next" />
                    </Button>
                </div>
            </div>
        )
    }

    // Letting users know about the drawer and clicking on the map
    const Mask1 = ({ handleNext2, handleBack2 }) => {
        return (
            <div onClick={() => handleNext2()} style={styles.mask}>
                <div style={styles.borderedText}>
                    <p style={styles.slideCounterText}>1/4</p>
                    <p style={styles.largeText}>
                        <FormattedMessage
                            description="Product tour mask"
                            defaultMessage="Tap an area on the map to see the forecast details"
                        />
                    </p>
                    <p style={styles.text}>
                        <FormattedMessage
                            description="Product tour mask"
                            defaultMessage="Once loaded, forecasts can be viewed offline."
                        />
                    </p>
                </div>
                <div style={styles.buttonSpacing}>
                    <Button variant="contained" onClick={e => handleBack2(e)} sx={{ background: 'var(--gray)' }}>
                        <FormattedMessage description="Product tour mask" defaultMessage="Back" />
                    </Button>
                    <Button variant="contained" onClick={() => handleNext2()}>
                        <FormattedMessage description="Product tour mask" defaultMessage="Next" />
                    </Button>
                </div>
                <div style={styles.abovePullDrawer}>
                    <p style={styles.textAbovePullDrawer}>
                        <FormattedMessage description="Product tour mask" defaultMessage="Tap the map... or pull up" />
                    </p>
                    <SvgIcon style={styles.iconAbovePullDrawer} viewBox={'0 0 72 24'}>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.600904 7.08166C0.764293 6.8879 1.01492 6.79004 1.26654 6.82177C2.83216 7.01918 5.20326 7.24581 7.54543 7.23964C9.92491 7.23338 12.1351 6.98464 13.4704 6.32142C13.84 6.13785 14.2885 6.28805 14.4722 6.65692C14.6559 7.02578 14.5052 7.47362 14.1356 7.6572C12.4625 8.48822 9.94063 8.72541 7.54852 8.7317C5.67514 8.73663 3.79547 8.5985 2.29921 8.44247C2.80955 9.59638 3.50943 10.6396 4.24665 11.7384C4.39435 11.9585 4.54354 12.1809 4.69301 12.4068C5.79543 14.0733 6.88128 15.8995 7.1179 18.2636C7.15893 18.6735 6.85928 19.0393 6.4486 19.0805C6.03792 19.1217 5.67174 18.8227 5.6307 18.4128C5.43271 16.4346 4.52957 14.868 3.4457 13.2296C3.3058 13.0181 3.16221 12.8046 3.01684 12.5885C2.05899 11.1646 1.02372 9.62564 0.457909 7.78069C0.383671 7.53862 0.437515 7.27541 0.600904 7.08166ZM5.52039 10.2248C5.77662 9.90161 6.24663 9.84687 6.57018 10.1025C16.4834 17.9344 29.9158 22.4064 42.0781 21.4773C54.1988 20.5514 65.0339 14.2748 69.9746 0.584299C70.1145 0.196597 70.5427 -0.0046455 70.931 0.134813C71.3193 0.274276 71.5206 0.70162 71.3807 1.08932C66.2105 15.4159 54.8056 22.0014 42.1913 22.965C29.6185 23.9254 15.8207 19.3142 5.64226 11.2727C5.31871 11.0171 5.26415 10.5479 5.52039 10.2248Z"
                            fill="currentColor"
                        />
                    </SvgIcon>
                </div>
            </div>
        )
    }

    // Shows the explorer view
    const Mask2 = ({ handleNext3, handleBack3 }) => {
        return (
            <div onClick={() => handleNext3()} style={styles.maskWithTextInTopHalf}>
                <div style={styles.borderedTextWithTextInTopHalf}>
                    <p style={styles.slideCounterText}>2/4</p>
                    <p style={styles.largeText}>
                        <FormattedMessage
                            description="Product tour mask"
                            defaultMessage="This is the explorer view. You can view lists of MINs, weather stations, etc."
                        />
                    </p>
                </div>
                <div style={styles.buttonSpacing}>
                    <Button variant="contained" onClick={e => handleBack3(e)} sx={{ background: 'var(--gray)' }}>
                        <FormattedMessage description="Product tour mask" defaultMessage="Back" />
                    </Button>
                    <Button variant="contained" onClick={() => handleNext3()}>
                        <FormattedMessage description="Product tour mask" defaultMessage="Next" />
                    </Button>
                </div>
            </div>
        )
    }

    // Shows min list filtering
    const Mask3 = ({ handleNext4 }) => {
        return (
            <div onClick={() => handleNext4()} style={styles.maskWithTextInTopHalf}>
                <div style={styles.borderedTextWithTextInTopHalf}>
                    <p style={styles.slideCounterText}>3/4</p>
                    <p style={styles.largeText}>
                        <FormattedMessage
                            description="Product tour mask"
                            defaultMessage="The list will filter based on the area shown on your screen. Zoom in or out to see other MINs."
                        />
                    </p>
                </div>
                <div style={styles.buttonSpacing}>
                    <Button variant="contained" onClick={e => handleBack4(e)} sx={{ background: 'var(--gray)' }}>
                        <FormattedMessage description="Product tour mask" defaultMessage="Back" />
                    </Button>
                    <Button variant="contained" onClick={() => handleNext4()}>
                        <FormattedMessage description="Product tour mask" defaultMessage="Next" />
                    </Button>
                </div>
                {isNotTooSmall && (
                    <div style={styles.aboveReportsFound}>
                        <p style={styles.textAboveReportsFound}>
                            <FormattedMessage
                                description="Product tour mask"
                                defaultMessage="Filtered based on the map view."
                            />
                        </p>
                        <SvgIcon style={styles.iconAboveReportsFound} viewBox={'0 0 72 24'}>
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.600904 7.08166C0.764293 6.8879 1.01492 6.79004 1.26654 6.82177C2.83216 7.01918 5.20326 7.24581 7.54543 7.23964C9.92491 7.23338 12.1351 6.98464 13.4704 6.32142C13.84 6.13785 14.2885 6.28805 14.4722 6.65692C14.6559 7.02578 14.5052 7.47362 14.1356 7.6572C12.4625 8.48822 9.94063 8.72541 7.54852 8.7317C5.67514 8.73663 3.79547 8.5985 2.29921 8.44247C2.80955 9.59638 3.50943 10.6396 4.24665 11.7384C4.39435 11.9585 4.54354 12.1809 4.69301 12.4068C5.79543 14.0733 6.88128 15.8995 7.1179 18.2636C7.15893 18.6735 6.85928 19.0393 6.4486 19.0805C6.03792 19.1217 5.67174 18.8227 5.6307 18.4128C5.43271 16.4346 4.52957 14.868 3.4457 13.2296C3.3058 13.0181 3.16221 12.8046 3.01684 12.5885C2.05899 11.1646 1.02372 9.62564 0.457909 7.78069C0.383671 7.53862 0.437515 7.27541 0.600904 7.08166ZM5.52039 10.2248C5.77662 9.90161 6.24663 9.84687 6.57018 10.1025C16.4834 17.9344 29.9158 22.4064 42.0781 21.4773C54.1988 20.5514 65.0339 14.2748 69.9746 0.584299C70.1145 0.196597 70.5427 -0.0046455 70.931 0.134813C71.3193 0.274276 71.5206 0.70162 71.3807 1.08932C66.2105 15.4159 54.8056 22.0014 42.1913 22.965C29.6185 23.9254 15.8207 19.3142 5.64226 11.2727C5.31871 11.0171 5.26415 10.5479 5.52039 10.2248Z"
                                fill="currentColor"
                            />
                        </SvgIcon>
                    </div>
                )}
            </div>
        )
    }

    // Shows a forecast
    const Mask4 = ({ handleNext5 }) => {
        return (
            <div onClick={() => handleNext5()} style={styles.maskWithTextInTopHalf}>
                <div style={styles.borderedTextWithTextInTopHalf}>
                    <p style={styles.slideCounterText}>4/4</p>
                    <p style={styles.largeText}>
                        <FormattedMessage
                            description="Product tour mask"
                            defaultMessage="Tapping on a forecast will show you the forecast details."
                        />
                    </p>
                    <p style={styles.text}>
                        <FormattedMessage
                            description="Product tour mask"
                            defaultMessage="You can pull the tab all the way up to see the forecast in full screen."
                        />
                    </p>
                </div>
                <div style={styles.buttonSpacing}>
                    <Button variant="contained" onClick={e => handleBack5(e)} sx={{ background: 'var(--gray)' }}>
                        <FormattedMessage description="Product tour mask" defaultMessage="Back" />
                    </Button>
                    <Button variant="contained" onClick={() => handleNext5()}>
                        <FormattedMessage description="Product tour mask" defaultMessage="Finish" />
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <>
            <Snackbar
                open={showNotification}
                autoHideDuration={AUTO_HIDE_DURATION}
                anchorOrigin={TOP_CENTER}
                onClose={() => setShowNotification(false)}
            >
                <Alert severity="success" color="info" variant="filled">
                    <FormattedMessage
                        description="Product tour mask"
                        defaultMessage="Missed the product tour? Tap Start to see it again."
                    />
                    <div style={styles.notificationButtons}>
                        <Button
                            onClick={() => handleCloseNotification()}
                            style={styles.noThanksButton}
                            sx={{ color: 'white' }}
                        >
                            <FormattedMessage description="Product tour mask" defaultMessage="No thanks" />
                        </Button>
                        <Button onClick={() => handleStartTour()} sx={{ color: 'white' }}>
                            <FormattedMessage description="Product tour mask" defaultMessage="Start" />
                        </Button>
                    </div>
                </Alert>
            </Snackbar>
            {showTour && <Mask handleNext1={handleNext1} handleSkip={handleSkip} />}
            {showTour1 && <Mask1 handleNext2={handleNext2} handleBack2={handleBack2} />}
            {showTour2 && <Mask2 handleNext3={handleNext3} handleBack3={handleBack3} />}
            {showTour3 && <Mask3 handleNext4={handleNext4} handleBack4={handleBack4} />}
            {showTour4 && <Mask4 handleNext5={handleNext5} handleBack5={handleBack5} />}
        </>
    )
}

const styles = {
    mask: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 10000,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    maskWithTextInTopHalf: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 10000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    welcome: {
        color: 'white',
        fontSize: '2rem',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '1rem',
        width: '85%',
    },
    subWelcomeText: {
        color: 'white',
        width: '85%',
        textAlign: 'center',
    },
    buttonSpacing: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '50%',
    },
    largeText: {
        color: 'white',
        fontSize: '1.5rem',
        textAlign: 'center',
        marginBottom: '1rem',
        marginTop: '0.5rem',
    },
    text: {
        color: 'white',
        fontSize: '1rem',
        textAlign: 'center',
        marginBottom: '1rem',
    },
    borderedText: {
        width: '85%',
        border: '2px solid white',
        backgroundColor: 'rgba(0,0,0,0.5)',
        padding: '0.5rem',
        borderRadius: '1rem',
        marginBottom: '1rem',
    },
    borderedTextWithTextInTopHalf: {
        width: '85%',
        border: '2px solid white',
        backgroundColor: 'rgba(0,0,0,0.5)',
        padding: '0.5rem',
        borderRadius: '1rem',
        marginTop: '3rem',
        marginBottom: '1rem',
    },
    spacing: {
        marginBottom: '100px',
    },
    abovePullDrawer: {
        position: 'absolute',
        bottom: 'calc(65px + env(safe-area-inset-bottom))',
        left: '15%',
        display: 'flex',
        flexDirection: 'row',
    },
    textAbovePullDrawer: {
        color: 'white',
        marginTop: '-8px',
        marginRight: '-1rem',
        alignItems: 'right',
    },
    iconAbovePullDrawer: {
        color: 'white',
        fontSize: '100px',
        transform: 'rotate(270deg)',
    },
    aboveReportsFound: {
        position: 'absolute',
        bottom: '48%',
        right: '14%',
        display: 'flex',
        flexDirection: 'row',
    },
    textAboveReportsFound: {
        color: 'white',
        marginTop: '-8px',
        marginRight: '-1rem',
        alignItems: 'right',
    },
    iconAboveReportsFound: {
        color: 'white',
        fontSize: '100px',
        transform: 'rotate(270deg)',
    },
    notificationButtons: {
        color: 'white',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '1rem',
    },
    noThanksButton: {
        marginRight: '1rem',
    },
    slideCounterText: {
        color: 'white',
        fontSize: '0.75rem',
        textAlign: 'right',
        width: '100%',
        marginBottom: 0,
        marginTop: 0,
    },
}
