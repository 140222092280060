import { useMemo } from 'react'

import useSWR from 'swr'
import { featureCollection } from '@turf/helpers'
import { FORECAST } from 'constants/products'

import client from 'clients/forecasts'
import { useLocale } from 'stores/SettingsStore'

export function useForecast(id) {
    const locale = useLocale()

    const key = ['forecasts', id, locale]

    return useSWR(key, () => client.product(id, locale))
}

export function useForecasts() {
    const locale = useLocale()

    const metadataKey = ['metadata', locale]
    const { data: metadata } = useSWR(metadataKey, () => client.metadata(locale))

    const areaKey = ['areas', locale]
    const { data: areas } = useSWR(areaKey, () => client.areas(locale))
    const formattedAreas = useMemo(() => {
        if (!metadata || !areas) {
            return null
        }
        const features = metadata.map(item => createFeature(item, areas)).filter(Boolean)

        return featureCollection(features)
    }, [metadata, areas, forecasts])

    const forecastsKey = ['forecasts', locale]
    const { data: forecasts } = useSWR(forecastsKey, () => client.products(locale))

    return {
        metadata,
        areas: formattedAreas,
        forecasts,
    }
}

// Helpers
function createFeature(item, areas) {
    const area = areas.features.find(area => area.properties.id === item.area.id)

    if (!area) {
        return
    }

    const { product } = item

    return {
        ...area,
        properties: {
            ...area.properties,
            product: FORECAST,
            id: product.id,
        },
    }
}
