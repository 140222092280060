import * as Colors from './colors'
import * as Types from './types'

export const ObservationTypes = new Set([Types.QUICK, Types.AVALANCHE, Types.SNOWPACK, Types.WEATHER, Types.INCIDENT])

export const ObservationColors = new Map([
    [Types.QUICK, Colors.QUICK],
    [Types.AVALANCHE, Colors.AVALANCHE],
    [Types.SNOWPACK, Colors.SNOWPACK],
    [Types.WEATHER, Colors.WEATHER],
    [Types.INCIDENT, Colors.INCIDENT],
])
