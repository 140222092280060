import { Predicates } from './'
import * as PredicatesUtils from './utils/predicates'
import * as Posts from './utils/posts'
import * as Utils from './utils'
import * as SPAW from './spaw'
import { NEWS as type } from 'prismic/types'

export function get(uid, locale) {
    return Posts.get(type, uid, locale)
}

export async function getList(params = {}, locale) {
    const { page, year, month, tags } = params
    const predicates = [PredicatesUtils.type(type)]
    const options = { orderings, page, locale }

    if (year) {
        const predicate = Predicates.year(PredicatesUtils.my(type, 'date'), year)

        predicates.push(predicate)
    }

    if (month) {
        const predicate = Predicates.month(PredicatesUtils.my(type, 'date'), month)

        predicates.push(predicate)
    }
    if (tags?.size > 0) {
        const predicate = PredicatesUtils.tags(tags)

        predicates.push(predicate)
    }

    const [list, spaw] = await Promise.all([Posts.getList(predicates, options), SPAW.get()])

    return {
        ...list,
        posts: [spaw, list.posts].flat().filter(Boolean),
    }
}

export function getRelated(uid, locale) {
    return Posts.getRelated(type, uid, orderings, locale)
}

// TOOD Need to use that function!
export function getForTags(tags, options = {}) {
    return Posts.getForTags({ type, tags, orderings, ...options })
}

export function getTags() {
    return Utils.getTags(type)
}

// Constants
const orderings = `[${PredicatesUtils.my(type, 'date')} desc]`
