import { useMemo } from 'react'

import useSWRImmutable from 'swr/immutable'

import {
    AVALANCHE_QUEBEC,
    KANANASKIS,
    PARKS_CANADA,
    PARKS_CANADA_BYK,
    PARKS_CANADA_GLACIER,
    PARKS_CANADA_JASPER,
    PARKS_CANADA_WATERTON,
} from 'constants/products/owners'
import Static from 'clients/static'
import { useSponsors as useSponsorsStore } from 'stores/PrismicDataStore'

const METADATA_KEY = ['metadata']

export function useForecastSponsor(forecast) {
    const sponsors = useSponsorsStore()
    const { data: metadata } = useSWRImmutable(METADATA_KEY, () => Static.getSponsors())

    return useMemo(() => {
        if (!sponsors || !metadata) return null

        let uid
        const owner = forecast.owner?.value
        switch (owner) {
            case AVALANCHE_QUEBEC:
                uid = metadata?.AvQForecast || 'avalanche-quebec'
                break
            case KANANASKIS:
                uid = 'kananaskis'
                break
            case PARKS_CANADA:
            case PARKS_CANADA_BYK:
            case PARKS_CANADA_GLACIER:
            case PARKS_CANADA_JASPER:
            case PARKS_CANADA_WATERTON:
                uid = 'parks-canada'
                break
            default:
                uid = metadata.Forecast || 'acf'
        }

        return getSponsorFromUid(sponsors, uid)
    }, [sponsors, forecast])
}

export function useSponsor(type) {
    const sponsors = useSponsorsStore()
    const { data: metadata } = useSWRImmutable(METADATA_KEY, () => Static.getSponsors())

    return useMemo(() => {
        if (!metadata || !sponsors) return null

        const uid = metadata[type]

        return getSponsorFromUid(sponsors, uid)
    }, [metadata, sponsors, type])
}

const getSponsorFromUid = (sponsors, uid) => {
    const sponsorFromPrismic = sponsors.find(sponsor => sponsor.uid === uid)

    if (!sponsorFromPrismic) return null

    return {
        uid: sponsorFromPrismic.uid,
        name: sponsorFromPrismic.data.name,
        url: sponsorFromPrismic.data.url,
        logo: sponsorFromPrismic.data['image-229'],
    }
}
