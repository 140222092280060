import {
    ACCIDENT,
    CLOSURE_ZONE,
    FORECAST,
    ICE_CLIMB,
    MOUNTAIN_CONDITIONS_REPORT,
    MOUNTAIN_INFORMATION_NETWORK,
    WEATHER_STATION,
} from 'constants/products'
import LayersIcon from '@mui/icons-material/Layers'
import Divider from '@mui/material/Divider'
import { default as List, default as ListSubheader } from '@mui/material/List'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { useBoolean } from 'hooks'
import { FormattedMessage } from 'react-intl'

import css from './LayerMenu.module.css'
import { LayerOption } from './LayerOption'

export const LayerMenu = () => {
    const [opened, show, hide, toggle] = useBoolean(false)

    return (
        <>
            <button onClick={toggle} className={css.Button}>
                <div className={css.Icon}>
                    <LayersIcon />
                </div>
            </button>
            <SwipeableDrawer anchor="right" open={opened} onClose={hide} onOpen={show}>
                <List>
                    <ListSubheader sx={styles.subheader}>
                        <FormattedMessage defaultMessage="Layer Visibility" description="Layer toggle drawer" />
                    </ListSubheader>
                    <LayerOption id={FORECAST} />
                    <Divider variant="inset" component="li" />
                    <LayerOption id={MOUNTAIN_INFORMATION_NETWORK} />
                    <Divider variant="inset" component="li" />
                    <LayerOption id={WEATHER_STATION} />
                    <Divider variant="inset" component="li" />
                    <LayerOption id={ACCIDENT} />
                    <Divider variant="inset" component="li" />
                    <LayerOption id={MOUNTAIN_CONDITIONS_REPORT} />
                    <Divider variant="inset" component="li" />
                    <LayerOption id={ICE_CLIMB} />
                    <Divider variant="inset" component="li" />
                    <LayerOption id={CLOSURE_ZONE} />
                </List>
            </SwipeableDrawer>
        </>
    )
}

const styles = {
    subheader: {
        padding: 2,
    },
}
