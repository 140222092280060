import { Predicates } from './'
import * as PredicateUtils from './utils/predicates'
import * as Posts from './utils/posts'
import { BLOG as type, STAFF } from 'prismic/types'

export function get(uid, locale) {
    return Posts.get(type, uid, locale, options)
}

export function getList(params = {}, locale) {
    const { year, month, category, page } = params
    const predicates = [PredicateUtils.type(type)]
    const options = { page, orderings, locale }

    if (year) {
        predicates.push(Predicates.year(PredicateUtils.my(type, 'date'), year))
    }

    if (month) {
        predicates.push(Predicates.month(PredicateUtils.my(type, 'date'), month))
    }

    if (category) {
        predicates.push(Predicates.at(PredicateUtils.my(type, 'category'), category))
    }

    return Posts.getList(predicates, options)
}

export function getRelated(uid, locale) {
    return Posts.getRelated(type, uid, orderings, locale)
}

export function getForTags(tags, options = {}) {
    return Posts.getForTags({ type, tags, orderings, ...options })
}

// Constants
const orderings = `[${PredicateUtils.my(type, 'date')} desc]`
const options = {
    fetchLinks: [STAFF + '.firstName', STAFF + '.lastName', STAFF + '.title'],
}
