import { useEffect, useMemo } from 'react'

import { UNIDENTIFIED_EVENT } from 'constants/products/mixpanel'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'

// Adapted from: https://stackoverflow.com/a/65008608
const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: [0.25],
}

/*
This hook address two scenarios:
1. When the user scrolls to a section and the section is visible, the hook starts timing the duration the section is visible before scrolling away.
2. Navigating away from the page or tab switch, the hook stops timing and tracks the duration the section was visible.
*/

export const useTrackSectionTimeOnScreen = ref => {
    const sendTrackEvent = useSendTrackEvent()

    const observer = useMemo(
        () =>
            // Scenario 1
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    // Start time when element is first visible
                    const now = new Date().getTime()
                    entry.target.dataset.startTime = now
                } else {
                    // Element is no longer visible; stop timing and track duration
                    trackViewDuration(entry.target.dataset, sendTrackEvent)

                    // Reset the timer
                    delete entry.target.dataset.startTime
                }
            }, observerOptions),
        [ref]
    )

    useEffect(() => {
        observer.observe(ref.current)
        window.addEventListener('visibilitychange', () => handleVisibilityChange(ref.current, sendTrackEvent))

        return () => {
            observer.disconnect()
            window.removeEventListener('visibilitychange', handleVisibilityChange)
        }
    }, [])
}

// Scenario 2
const handleVisibilityChange = (entry, sendTrackEvent) => {
    if (!entry) return

    // Page becomes hidden by tab switch or close; stop timing and track duration
    if (document.visibilityState === 'hidden') {
        trackViewDuration(entry.dataset, sendTrackEvent)
        // Reset the timer
        delete entry.dataset?.startTime
    }
}

const trackViewDuration = (dataset, sendTrackEvent) => {
    const startTime = dataset?.startTime

    if (!startTime) return

    const endTime = new Date().getTime()
    // Round to nearest 0.01s. start and end time are in ms, hence divide by 10, not multiply by 100
    const duration = Math.round((endTime - startTime) / 10) / 100

    const trackingObject = {
        duration_s: duration,
        // Add any properties per the dataset (e.g. `data-*` on the HTML element)
        // See src/layouts/products/forecast/Summary.jsx
        ...dataset,
    }
    delete trackingObject.startTime // Clear this since duration covers it
    delete trackingObject.eventName // Clear this since it is redundant

    const eventName = dataset.eventName || UNIDENTIFIED_EVENT

    sendTrackEvent(eventName, trackingObject)
}
