import { useEffect } from 'react'

import { WEATHER_STATION_OPENED } from 'constants/products/mixpanel'
import { FormattedMessage } from 'react-intl'

import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import Shim from 'components/Shim/Shim'
import { HeaderWithLocate } from 'components/text/HeaderWithLocate'
import { Footer, Metadata, Station } from 'components/weather/station'
import * as Async from 'contexts/async'
import { useMeasurements, useWeatherStations } from 'hooks/data/weather'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'

export const WeatherStation = ({ id }) => {
    const sendTrackEvent = useSendTrackEvent()
    const { stations } = useWeatherStations()
    const station = stations.find(station => station.stationId === id)

    useEffect(() => {
        sendTrackEvent(WEATHER_STATION_OPENED, { id })
    }, [id])

    return (
        <DetailsWrapper>
            <Async.Provider value={useMeasurements(id)}>
                <Async.Pending>
                    <Shim horizontal>
                        <FormattedMessage
                            description="Layout weather/station/WeatherStation"
                            defaultMessage="Loading measurements..."
                        />
                    </Shim>
                </Async.Pending>
                <Async.Found>
                    <Content station={station} id={id} />
                </Async.Found>
                <Async.Offline />
            </Async.Provider>
        </DetailsWrapper>
    )
}

const Content = ({ payload, station, id }) => {
    return (
        <>
            <HeaderWithLocate text={station.name} location={[station.longitude, station.latitude]} />
            <Shim horizontal>
                <Metadata {...station} id={id} />
            </Shim>
            <Station measurements={payload} />
            <Shim horizontal>
                <Footer>
                    <p>
                        <FormattedMessage
                            defaultMessage="Avalanche Canada makes no guarantee regarding the completeness or accuracy of
this data."
                            description="Weather station disclaimer"
                        />
                    </p>
                </Footer>
            </Shim>
        </>
    )
}
