import Client from './index'
import * as Predicates from './utils/predicates'
import * as Events from './events'
import * as News from './news'
import * as Blog from './blog'
import * as Types from 'prismic/types'

export async function get(uid, locale) {
    const predicate = Predicates.uid(Types.STATIC_PAGE, uid)
    const options = { ...OPTIONS, locale }
    const page = await Client.queryFirst(predicate, options)

    if (!page) {
        return null
    }

    const { content } = page.data

    // Add complete staff documents to content, fetchLinks only returns first element for rich text
    // https://prismic.io/docs/technologies/fetch-linked-document-fields-javascript
    for (const slice of content) {
        const { value } = slice

        switch (slice.slice_type) {
            case 'staff-set':
                const ids = value.flat().map(item => item.staff.id)
                const predicate = Predicates.ids(ids)
                const options = { pageSize: ids.length, locale: '*' }
                const { results } = await Client.query(predicate, options)
                const staffById = new Map(results.map(staff => [staff.id, staff]))

                slice.value = value.map(item => {
                    const { id } = item.staff

                    return {
                        ...item,
                        staff: staffById.has(id) ? staffById.get(id) : item.staff,
                    }
                })

                break
            case 'feedSplash':
                const [{ header, hash, type, tags }] = value
                const client = ClientsByType.get(type)

                slice.value = {
                    header,
                    hash,
                    documents: await client.getForTags(tags, undefined, locale),
                }

                break
            default:
                break
        }
    }

    return page
}

const OPTIONS = {
    fetchLinks: [
        Types.SPONSOR + '.name',
        Types.SPONSOR + '.url',
        Types.SPONSOR + '.image-229',
        Types.STATIC_PAGE + '.title',
    ],
}

// Constants
const ClientsByType = new Map([
    ['Events', Events],
    ['Blogs', Blog],
    ['News', News],
])
