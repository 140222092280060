import { useEffect } from 'react'

import { FORECAST_OPENED } from 'constants/products/mixpanel'
import { FormattedMessage } from 'react-intl'

import { CloseButton } from 'components/BottomSheet/CloseButton/CloseButton'
import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import Shim from 'components/Shim/Shim'
import { Sponsor } from 'components/Sponsor/Sponsor'
import { useForecasts } from 'hooks/data/forecasts'
import * as Components from 'layouts/products/forecast'
import { useGoToHome } from 'stores/ViewStore'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import { useSetForecastId } from 'stores/MixpanelHistoryStore'

export const Forecast = ({ id }) => {
    const { forecasts } = useForecasts()
    const product = forecasts.find(forecast => forecast.id === id)
    const goToHome = useGoToHome()
    const sendTrackEvent = useSendTrackEvent()
    const setForecastId = useSetForecastId()

    /* Forecast Pre-Fetching:
    When the app first loads, we fetch all the products and store them in the `useForecasts()` hook.         
    The reason for this setup is that we do not know all the product ids when we first load the page and thus cannot intercept their requests via the service worker without navigating to them (and storing them in the runtime cache).
    As we already have the cached products, we just do a lookup in this component.
    */

    useEffect(() => {
        sendTrackEvent(FORECAST_OPENED, { forecastId: id, forecast: product?.report })
    }, [id])

    useEffect(() => {
        setForecastId(id)

        return () => {
            setForecastId(null)
        }
    }, [id])

    return (
        <>
            <CloseButton callback={goToHome} />
            {product ? (
                <ForecastContent product={product} />
            ) : (
                <Shim horizontal>
                    <FormattedMessage
                        defaultMessage="Error displaying forecast"
                        description="Forecast details view component"
                    />
                </Shim>
            )}
        </>
    )
}

// Utils and Constants
function ForecastContent({ product }) {
    return (
        <Components.Provider product={product}>
            <DetailsWrapper>
                <Shim horizontal>
                    <Components.ForecastMetadata />
                    <Components.Notifications />
                    <Components.Headline />
                </Shim>
                <Components.TabSet />
                <Shim horizontal>
                    <div style={style.flex}>
                        <Components.ForecastMetadataBottom />
                        <Sponsor type="Forecast" payload={{ product }} />
                    </div>
                </Shim>
                <Components.Footer />
            </DetailsWrapper>
        </Components.Provider>
    )
}

const style = {
    flex: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'flex-start',
    },
}
