import { ObservationTypes } from 'constants/products/min'

export function indexOf(type) {
    return TYPES.indexOf(type)
}

export function sorter(a, b) {
    if (indexOf(a) < indexOf(b)) {
        return -1
    }

    if (indexOf(a) > indexOf(b)) {
        return 1
    }

    return 0
}

export function split(types = new Set()) {
    return Array.from(types).reduce(
        (options, type) => {
            const [obtype, tag] = type.split('-')

            options.obtypes.add(obtype)

            if (tag) {
                options.tags.add(tag)
            }

            return options
        },
        { tags: new Set(), obtypes: new Set() }
    )
}

const TYPES = Array.from(ObservationTypes)
