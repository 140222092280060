import * as React from 'react'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import { CldImage, getCldImageUrl } from 'next-cloudinary'

import * as Arrays from 'utils/avcan-website-utils/array'
import * as Hooks from 'hooks'
import { useImageSize } from 'hooks/useImageSize'

import styles from './Figure.module.css'

Figure.propTypes = {
    children: PropTypes.element,
}

export function Figure({ children }) {
    const className = clsx(styles.Figure)

    return <figure className={className}>{children}</figure>
}

Image.propTypes = {
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
}

export function Image({ url, sizes, alt, onError, onLoad }) {
    const [ref, dimensions] = Hooks.useDimensions()
    const sources = useSources(url, dimensions?.width, sizes)
    const urlToGetSize = url.search('{size}') > -1 ? url.replace('{size}', 999) : url // Arbitrarily choose 999px as the size to get the image dimensions
    const dims = useImageSize(urlToGetSize)
    const { width, height } = dims || {}

    return (
        width &&
        height && (
            <a
                href={url}
                data-pswp-src={url}
                data-pswp-width={width}
                data-pswp-height={height}
                data-pswp-srcset={sources.srcset}
            >
                <img ref={ref} {...sources} onError={onError} onLoad={onLoad} alt={alt} />
            </a>
        )
    )
}

export function CloudinaryImage({ url, width, height, alt, cloudName }) {
    const srcset = BREAKPOINTS.map(breakpoint => {
        const cldImageUrl = getCldImageUrl(
            { src: url, width: breakpoint, height },
            {
                cloud: {
                    cloudName,
                },
            }
        )
        return `${cldImageUrl} ${breakpoint}w`
    }).join(', ')

    return (
        <a
            href={url}
            data-pswp-src={url}
            data-pswp-width={width}
            data-pswp-height={height}
            data-pswp-srcset={srcset}
            style={{
                aspectRatio: width / height,
                position: 'relative',
            }}
        >
            <CldImage
                fill
                src={url}
                alt={alt}
                config={{
                    cloud: {
                        cloudName,
                    },
                }}
            />
        </a>
    )
}

// This is only for AvCan-hosted images, not Cloudinary
export function ImagePending({ children }) {
    const { status, ...events } = useEventsStatus()
    const className = clsx(ClassNames.get(status))

    return (
        <div className={className}>
            {React.Children.toArray(children).map(child => {
                switch (child.type) {
                    case Image:
                        return React.cloneElement(child, events)
                    default:
                        return child
                }
            })}
        </div>
    )
}

// Utils
export function interpolate(url, size) {
    return url.replace('{size}', typeof size === 'number' ? Math.ceil(size) : size)
}

const useEventsStatus = () => {
    const [status, setStatus] = React.useState(PENDING)

    return React.useMemo(
        () => ({
            status,
            onLoad() {
                setStatus(RESOLVED)
            },
            onError() {
                setStatus(REJECTED)
            },
        }),
        [status]
    )
}

const useSources = (url, width, sizes = SIZES) => {
    return React.useMemo(() => {
        const replace = interpolate.bind(null, url.replaceAll(' ', '%20'))

        return {
            src: typeof width !== 'number' ? replace(width) : null,
            srcset: BREAKPOINTS.map(breakpoint => `${replace(breakpoint)} ${breakpoint}w`),
            sizes,
        }
    }, [url, width, sizes])
}

const BREAKPOINTS = Arrays.range(300, 2100, 150)
const SIZES = ['100%']
const PENDING = 'pending'
const REJECTED = 'rejected'
const RESOLVED = 'resolved'
const ClassNames = new Map([
    [PENDING, styles.Pending],
    [RESOLVED, styles.Resolved],
    [REJECTED, styles.Rejected],
])
