import * as React from 'react'

import { merge } from 'lodash'

import * as Products from 'constants/products'
import * as Storage from 'services/storage'
import { omit } from 'utils/avcan-website-utils/object'

const LayersContext = React.createContext()

export function Provider({ children }) {
    const [layers, setLayers] = React.useState(() => {
        const layers = { ...DEFAULT_MAP_LAYERS }

        if (Storage.Local.has(STORAGE_KEY)) {
            // Everything comes from local storage except for the forecast layer (forced to always be visible)
            const data = omit(Storage.Local.get(STORAGE_KEY, {}, reviver), Products.FORECAST)
            merge(layers, data)
        }

        return layers
    })

    React.useEffect(() => {
        Storage.Local.set(STORAGE_KEY, layers, replacer)
    }, [layers])

    const value = React.useMemo(() => {
        return {
            layers,
            toggle(id) {
                setLayers(layers => ({
                    ...layers,
                    [id]: {
                        ...layers[id],
                        visible: !layers[id].visible,
                    },
                }))
            },
            setFilterValue(id, name, value) {
                setLayers(layers => ({
                    ...layers,
                    [id]: {
                        ...layers[id],
                        filters: {
                            ...layers[id].filters,
                            [name]: value,
                        },
                    },
                }))
            },
        }
    }, [layers])

    return <LayersContext.Provider value={value}>{children}</LayersContext.Provider>
}

// Hooks
export function useLayers() {
    return React.useContext(LayersContext)
}

export function useLayer(id) {
    const { layers, toggle, setFilterValue } = useLayers()
    const layer = layers[id]

    return React.useMemo(
        () => ({
            ...layer,
            toggle() {
                toggle(id)
            },
            setFilterValue(name, value) {
                setFilterValue(id, name, value)
            },
        }),
        [layer, id, toggle, setFilterValue]
    )
}

// Utils & constants
const STORAGE_KEY = 'map-layers'
function replacer(key, value) {
    if (typeof value === 'object' && value instanceof Set) {
        return [...value]
    }

    return value
}
function reviver(key, value) {
    if (Array.isArray(value)) {
        return new Set(value)
    }

    return value
}
const DEFAULT_MAP_LAYERS = {
    [Products.FORECAST]: {
        visible: true,
    },
    [Products.MOUNTAIN_CONDITIONS_REPORT]: {
        visible: true,
    },
    [Products.WEATHER_STATION]: {
        visible: true,
    },
    [Products.MOUNTAIN_INFORMATION_NETWORK]: {
        visible: true,
        filters: {
            days: 7,
            types: new Set(),
        },
    },
    [Products.ACCIDENT]: {
        visible: true,
    },
    [Products.CLOSURE_ZONE]: {
        visible: true,
    },
    [Products.ICE_CLIMB]: {
        visible: true,
    },
}
