import React from 'react'

import ReactHtmlParser from 'react-html-parser'
import Button from '@mui/material/Button'
import { SPAW } from 'constants/products'
import { FormattedMessage } from 'react-intl'

import { useForecast } from '../Context'
import * as Alert from 'components/alert'
import { InnerHTML } from 'components/misc/InnerHTML'
import { useSetExplorerView, useSetLayout, useSetProductID } from 'stores/ViewStore'
import { EXPLORER_LAYOUT } from 'constants/layout'

import styles from './Notifications.module.css'

export default function Notifications() {
    const forecast = useForecast()
    const { notifications = [] } = forecast

    return (
        <div className={styles.Set}>
            {notifications.map(notification => {
                if (notification.type === SPAW) {
                    return <SPAWNotification content={notification.content} />
                }
                return <Notification {...notification} />
            })}
        </div>
    )
}

const Notification = ({ type, content }) => {
    const Component = Alert.forType(type)

    return (
        <Component>
            <InnerHTML>{content}</InnerHTML>
        </Component>
    )
}

const SPAWNotification = ({ content }) => {
    const Component = Alert.forType(SPAW)

    const setLayout = useSetLayout()
    const setExplorerView = useSetExplorerView()
    const setProductID = useSetProductID()

    const handleOnClick = () => {
        setLayout(EXPLORER_LAYOUT)
        setExplorerView(SPAW)
        setProductID(null)
    }

    const jsx = ReactHtmlParser(content)

    return (
        <Component>
            <Button onClick={handleOnClick} variant="text" style={stylesObj.link}>
                {jsx[0]?.props?.children[0] ? (
                    jsx[0]?.props?.children[0]
                ) : (
                    <FormattedMessage defaultMessage="SPAW in Effect" description="Link to SPAW" />
                )}
            </Button>
        </Component>
    )
}

const stylesObj = {
    link: {
        textTransform: 'none',
        textDecoration: 'underline',
        padding: 0,
        fontSize: 'inherit',
        color: 'inherit',
        fontWeight: 'inherit',
    },
}
